var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"greyBase","dark":"","extended":!_vm.$vuetify.breakpoint.mobile,"flat":"","extension-height":"55"}},[_c('v-toolbar-title',[_c('b',{staticClass:"mr-3"},[_vm._v("Forecasts")])]),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":"","light":!_vm.$store.state.darkMode,"outlined":"","rounded":"","color":_vm.toggleView == 0 ? 'teal' : _vm.toggleView == 1 ? 'red' : 'blue'},model:{value:(_vm.toggleView),callback:function ($$v) {_vm.toggleView=$$v},expression:"toggleView"}},[_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":_vm.toggleView == 0 ? 'teal' : 'primaryText'}},[_vm._v("widgets")]),_c('span',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("Dashboard")])],1),_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":_vm.toggleView == 1 ? 'red' : 'primaryText'}},[_vm._v("account_balance")]),_c('span',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("Supplier Forecasts")])],1),_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.toggleView == 2 ? 'blue' : 'primaryText'}},[_vm._v("attach_money")]),_c('span',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("Customer Forecasts")])],1)],1)],1),_c('v-card',{staticClass:"mx-auto",style:({
        'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '15px',
        'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
      }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
          height: !_vm.$vuetify.breakpoint.mobile ? '90vh' : '90vh',
          'overflow-y': 'auto',
        })},[(_vm.toggleView == 0)?_c('div',[_c('Dashboard',{key:_vm.toggleView})],1):(_vm.toggleView == 1)?_c('div',[_c('Forecast',{attrs:{"type":'Supplier',"clearSelection":_vm.toggleView}})],1):(_vm.toggleView == 2)?_c('div',[_c('Forecast',{attrs:{"type":'Customer',"clearSelection":_vm.toggleView}})],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }