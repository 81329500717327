<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important" >
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3">Forecasts</b>
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn-toggle
          mandatory
          :light="!$store.state.darkMode"
          outlined
          v-model="toggleView"
          rounded
          :color="toggleView == 0 ? 'teal' : toggleView == 1 ? 'red' : 'blue'"
        >
          <v-btn small outlined>
            <v-icon left small :color="toggleView == 0 ? 'teal' : 'primaryText'"
              >widgets</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Dashboard</span>
          </v-btn>
          <v-btn small outlined>
            <v-icon left small :color="toggleView == 1 ? 'red' : 'primaryText'"
              >account_balance</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Supplier Forecasts</span
            >
          </v-btn>
          <v-btn small outlined>
            <v-icon small left :color="toggleView == 2 ? 'blue' : 'primaryText'"
              >attach_money</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Customer Forecasts</span
            >
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
            'overflow-y': 'auto',
          }"
        >
          <div v-if="toggleView == 0">
            <Dashboard :key="toggleView" />
          </div>
          <div v-else-if="toggleView == 1">
            <Forecast :type="'Supplier'" :clearSelection="toggleView" />
          </div>
          <div v-else-if="toggleView == 2">
            <Forecast :type="'Customer'" :clearSelection="toggleView" />
          </div>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import Dashboard from "../components/Forecast/Dashboard.vue";
// import SupplierForecast from '../components/Forecast/SupplierForecast.vue'
// import CustomerForecast from '../components/Forecast/CustomerForecast.vue'
import Forecast from "../components/Forecast/Forecast.vue";
export default {
  components: {
    Dashboard,
    Forecast,
    // SupplierForecast,
    // CustomerForecast
  },
  data: () => ({
    toggleView: 0,
  }),
};
</script>