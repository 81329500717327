<template>
  <div>
    <v-card flat>
      <v-toolbar flat>
        <!--       
        <v-autocomplete
          class="ml-2"
          :items="organisations.data.filter(x=>x['is'+type])"
          item-text="relatedOrganisation.name"
          rounded
          :loading="loadingOrganisations"
          :label="type"
          item-value="relatedOrganisation.id"
          style="max-width: 400px; min-width: 300px"
          hide-details
          outlined
          return-object
          v-model="selectedCustomer"
          @change="getForecast()"
          clearable
          dense
        >
          <template v-slot:selection="data">
            <v-row align="center">
              <v-avatar
                size="32"
                :color="
                  data.item && data.item.relatedOrganisation.logo
                    ? 'white'
                    : 'secondary'
                "
                class="mr-2"
                v-if="data.item"
              >
                <v-img
                  v-if="data.item.relatedOrganisation.logo"
                  contain
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
              <span v-if="data.item.relatedOrganisation">
                {{ data.item.relatedOrganisation.name }}
              </span>
            </v-row>
          </template>
          <template v-slot:item="data">
            <v-list-item-action>
              <v-avatar
                size="32"
                :color="
                  data.item.relatedOrganisation.logo ? 'white' : 'secondary'
                "
              >
                <v-img
                  contain
                  v-if="data.item.relatedOrganisation.logo"
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                data.item.relatedOrganisation.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="data.item.relatedOrganisation.alias"
                >{{ data.item.relatedOrganisation.alias }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-autocomplete> -->
        <v-spacer></v-spacer>
        <h4>Season:</h4>
        <v-btn
          :loading="loadingSeasons"
          icon
          class="mx-1"
          color="primary"
          @click="addSeason()"
        >
          <v-icon>add_circle_outline</v-icon>
        </v-btn>
        <v-chip-group
          class="ml-2"
          :loading="loadingSeasons"
          mandatory
          active-class="teal--text"
        >
          <v-chip
            v-for="season in availableSeasons"
            :value="season"
            :key="season.id"
            @click="getForecast(season)"
            @contextmenu="clickSeason($event, season)"
            >{{ season.name }}</v-chip
          >
        </v-chip-group>
        <v-spacer></v-spacer>
        <v-btn-toggle
          mandatory
          :light="!$store.state.darkMode"
          outlined
          v-model="toggleTypeView"
          rounded
          :color="'blue'"
        >
          <v-btn small outlined>
            <span style="text-transform: none; color: var(--v-primaryText-base)">Containers</span>
          </v-btn>
          <v-btn small outlined>
            <span style="text-transform: none; color:  var(--v-primaryText-base)">Pallets</span>
          </v-btn>
          <v-btn small outlined>
            <span style="text-transform: none; color:  var(--v-primaryText-base)">Units</span>
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-card
              :loading="loadingForecast"
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-toolbar flat dense>
                <v-toolbar-title style="color: grey">Customers</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-list dense style="height: 65vh; overflow-y: auto" class="mx-0 px-0">
                  <v-list-item v-if="forecastItems.customers.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No customers found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="customer in forecastItems.customers"
                    :key="customer.organisationId"
                  >
                    <v-list-item-action class="mr-0 pr-1">
                      <v-avatar
                        :color="
                          customer.organisation.logo ? 'white' : 'secondary'
                        "
                        size="50"
                      >
                        <v-img
                          v-if="customer.organisation.logo"
                          :src="customer.organisation.logo"
                          contain
                        ></v-img>
                        <h3 v-else>
                          {{ customer.organisation.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="customer.organisation.friendlyName">{{
                          customer.organisation.friendlyName
                        }}</span>
                        <span v-else>
                          {{ customer.organisation.name }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        Containers: {{ customer.containerQuantity.toLocaleString('en')  }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        Pallets: {{ customer.palletQuantity.toLocaleString('en')  }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        Units: {{ customer.unitQuantity.toLocaleString('en')  }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        {{ customer.percentage }}%
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-card
              :loading="loadingForecast"
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-toolbar flat dense>
                <v-toolbar-title style="color: grey">Suppliers</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-list dense style="height: 65vh; overflow-y: auto" class="mx-0 px-0">
                  <v-list-item v-if="forecastItems.customers.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No suppliers found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="supplier in forecastItems.suppliers"
                    :key="supplier.organisationId"
                  >
                    <v-list-item-action class="mr-0 pr-1">
                      <v-avatar
                        :color="
                          supplier.organisation.logo ? 'white' : 'secondary'
                        "
                        size="50"
                      >
                        <v-img
                          v-if="supplier.organisation.logo"
                          :src="supplier.organisation.logo"
                          contain
                        ></v-img>
                        <h3 v-else>
                          {{ supplier.organisation.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="supplier.organisation.alias">{{
                          supplier.organisation.alias
                        }}</span>
                        <span v-else>
                          {{ supplier.organisation.name }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        Containers: {{ supplier.containerQuantity.toLocaleString('en')  }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        Pallets: {{ supplier.palletQuantity.toLocaleString('en')  }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        Units: {{ supplier.unitQuantity.toLocaleString('en')  }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        {{ supplier.percentage }}%
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <DynamicSmoothLineChart
              v-if="toggleTypeView == 0"
              :data="constructedContainerData"
              :key="containerGraphKey"
              :labels="weeks()"
              title="Container Forecast"
              defaultChart="line"
              xAxisLabel="Weeks"
              :textColor="'grey'"
            />
            <DynamicSmoothLineChart
              v-else-if="toggleTypeView == 1"
              :data="constructedPalletData"
              :key="palletGraphKey"
              :labels="weeks()"
              title="Pallet Forecast"
              defaultChart="line"
              xAxisLabel="Weeks"
              :textColor="'grey'"
            />
            <DynamicSmoothLineChart
              v-else-if="toggleTypeView == 2"
              :data="constructedUnitData"
              :key="unitGraphKey"
              :labels="weeks()"
              title="Unit Forecast"
              defaultChart="line"
              xAxisLabel="Weeks"
              :textColor="'grey'"
            />
            <!-- <div class="mt-2">
              <v-row class="px-2">
                <v-col cols="12" sm="6">
                  <v-card
                    flat
                    style="
                      border-radius: 20px;
                      background-color: var(--v-toolbar-lighten1) !important;
                    "
                  >
                    <v-card-title style="font-size: 16px" class="mb-0 pb-0">
                      <v-icon small class="mr-2" color="grey"
                        >location_on</v-icon
                      >
                      Top Destinations</v-card-title
                    >
                    <v-card-text class="my-0 py-0">
                      <v-list dense class="my-0 py-0">
                        <v-list-item v-if="topCountries.length == 0">
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No country data</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="(country, index) in topCountries"
                          :key="index"
                        >
                          <v-list-item-action>
                            <v-avatar size="32">
                              <v-img
                                :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                                contain
                              ></v-img>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ country.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ country.units }} Units
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ country.percentage }}%
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card
                    flat
                    style="
                      border-radius: 20px;
                      background-color: var(--v-toolbar-lighten1) !important;
                    "
                  >
                    <v-card-title style="font-size: 16px" class="mb-0 pb-0">
                      <v-icon small class="mr-2" color="grey"
                        >category</v-icon
                      >
                      Top Varieties</v-card-title
                    >
                    <v-card-text class="my-0 py-0">
                      <v-list dense class="my-0 py-0">
                        <v-list-item v-if="topVarieties.length == 0">
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No product data</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="(variety, index) in topVarieties"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ variety.product }} - {{ variety.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ variety.units }} Units
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ variety.percentage }}%
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="confirmDelete"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDelete = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like delete this season and all of its
            forecasts?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmDelete = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="deletingSeason"
              @click="deleteSeason()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shiftSeasonModal" width="500px">
      <v-card v-if="season">
        <v-toolbar dense flat color="transparent">
          <v-toolbar-title> {{ season.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shiftSeasonModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            How many weeks would you like to adjust the season by: <br />
          </p>
          <v-col cols="12" class="text-center my-0 py-0">
            <el-input-number
              :value="weekAdjustment"
              size="mini"
              v-model="weekAdjustment"
            ></el-input-number>
          </v-col>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              :disabled="weekAdjustment == 0"
              class="mx-2"
              text
              small
              :loading="shiftingSeason"
              @click="shiftSeason()"
              >Process Shift</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item @click="editSeason()">
          <v-list-item-action class="mr-1">
            <v-icon color="blue">edit</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Edit Season </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="shiftSeasonModal = true">
          <v-list-item-action class="mr-1">
            <v-icon color="teal">refresh</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Shift Season </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="season && season.id" @click="confirmDelete = true">
          <v-list-item-action class="mr-1">
            <v-icon color="red">delete</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Delete Season </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="seasonModal"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Add Season </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="!season.name || !season.start || !season.end"
            :loading="savingSeason"
            @click="saveSeason"
          >
            <v-icon color="secondary">save</v-icon>
          </v-btn>
          <v-btn text @click="seasonModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="my-2" align="center">
            <v-col cols="12" sm="6">
              <v-text-field
                label="Name*"
                v-model="season.name"
                autofocus
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                class="my-2"
                hide-details
                ref="productSelect"
                hide-no-data
                hide-selected
                label="Product*"
                dense
                outlined
                :items="filterProducts"
                clearable
                item-text="name"
                item-value="id"
                :menu-props="{ closeOnContentClick: true }"
                v-model="season.productId"
                :loading="loadingProduct"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="data.item.parentProduct"
                      style="font-size: 12px"
                    >
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.start ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.start">
                          {{ season.start }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Start Date
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.start"
                    :max="season.end"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.end ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.end">
                          {{ season.end }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> End Date </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.end"
                    :min="season.start"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DynamicSmoothLineChart from "../Charts/DynamicSmoothLineChart.vue";

import * as moment from "moment";
export default {
  components: {
    DynamicSmoothLineChart,
  },
  data: () => ({
    availableSeasons: [],
    confirmDelete: false,
    deletingSeason: false,
    forecastItems: {
      customers: [],
      suppliers: [],
      weeks: [],
    },
    graphKey: 100,
    containerGraphKey: 120,
    palletGraphKey: 130,
    unitGraphKey: 140,
    loadingSeasons: false,
    loadingForecast: false,
    loadingProduct: false,
    organisations: {
      data: [],
    },
    products: { data: [] },
    savingSeason: false,
    season: {},
    seasonModal: false,
    selectedSeason: null,
    shiftingSeason: false,
    shiftSeasonModal: false,
    showMenu: false,
    toggleTypeView: 0,
    topCountries: [],
    topVarieties: [],
    weekAdjustment: 0,
    x: 0,
    y: 0,
  }),
  watch: {
    toggleTypeView() {
      this.containerGraphKey++;
      this.palletGraphKey++;
      this.unitGraphKey++;
    },
  },
  computed: {
    constructedContainerData() {
      let weeks = this.weeks(true);
      let containerSupply = [];
      let containerDemand = [];
      console.log(weeks);
      console.log(this.forecastItems);
      for (let i = 0; i < weeks.length; i++) {
        let week = weeks[i];
        let result = this.forecastItems.weeks.find((x) => x.week == week.name);
        if (result) {
          containerSupply.push(result.supplierContainerQuantity);
          containerDemand.push(result.customerContainerQuantity);
        } else {
          containerSupply.push(null);
          containerDemand.push(null);
        }
      }
      let data = [
        {
          data: containerSupply,
          type: "bar",
          color: "#9e140d",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Supply",
        },
        {
          data: containerDemand,
          type: "bar",
          color: "#3F51B5",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Demand",
        },
      ];
      return data;
    },
    constructedPalletData() {
      let weeks = this.weeks(true);
      let palletSupply = [];
      let palletDemand = [];
      for (let i = 0; i < weeks.length; i++) {
        let week = weeks[i];
        let result = this.forecastItems.weeks.find((x) => x.week == week.name);
        if (result) {
          palletSupply.push(result.supplierPalletQuantity);
          palletDemand.push(result.customerPalletQuantity);
        } else {
          palletSupply.push(null);
          palletDemand.push(null);
        }
      }
      let data = [
        {
          data: palletSupply,
          type: "bar",
          color: "#9e140d",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Supply",
        },
        {
          data: palletDemand,
          type: "bar",
          color: "#3F51B5",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Demand",
        },
      ];
      return data;
    },
    constructedUnitData() {
      let weeks = this.weeks(true);
      let unitSupply = [];
      let unitDemand = [];
      for (let i = 0; i < weeks.length; i++) {
        let week = weeks[i];
        let result = this.forecastItems.weeks.find((x) => x.week == week.name);
        if (result) {
          unitSupply.push(result.supplierUnitQuantity);
          unitDemand.push(result.customerUnitQuantity);
        } else {
          unitSupply.push(null);
          unitDemand.push(null);
        }
      }
      let data = [
        {
          data: unitSupply,
          type: "bar",
          color: "#9e140d",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Supply",
        },
        {
          data: unitDemand,
          type: "bar",
          color: "#3F51B5",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Demand",
        },
      ];
      return data;
    },
    filterProducts() {
      let result = this.products.data.filter(
        (x) => x.type == "Product Group" || x.type == "Product"
      );
      return result;
    },
  },
  created() {
    this.getProducts();
    this.getSeasons();
    // this.getRelations();
  },
  methods: {
    addSeason() {
      this.season = {
        year: new Date().getFullYear(),
        name: "",
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().endOf("year").format("YYYY-MM-DD"),
      };
      this.seasonModal = true;
    },
    clickSeason(e, item) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.season = item;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async deleteSeason() {
      this.deletingSeason = true;
      await this.$API.updateSeason({
        id: this.season.id,
        isActive: false,
        isDeleted: true,
      });
      this.availableSeasons = this.availableSeasons.filter(
        (x) => x.id != this.season.id
      );
      this.deletingSeason = false;
      this.confirmDelete = false;
      this.season = {};
    },
    editSeason() {
      this.seasonModal = true;
    },
    async getProducts() {
      this.loadingProducts = true;
      this.products = await this.$API.getProducts({});
      this.loadingProducts = false;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      if (
        localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
      ) {
        this.organisations = JSON.parse(
          localStorage[
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          ]
        );
      }
      this.organisations = await this.$API.getRelationBasic({});
      localStorage.setItem(
        "relatedOrganisations_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.organisations)
      );
      this.loadingOrganisations = false;
    },
    async getSeasons() {
      this.loadingSeasons = true;
      this.availableSeasons = await this.$API.getSeasons();
      if (this.availableSeasons.length > 0 && !this.selectedSeason) {
        this.selectedSeason = this.availableSeasons[0];
        this.getForecast(this.selectedSeason)
      }
      this.loadingSeasons = false;
    },
    async getForecast(season = null) {
      if (season) {
        this.selectedSeason = season;
      }
      if (this.selectedSeason) {
        this.loadingForecast = true;
        this.forecastItems = await this.$API.getForecast({
          params: {
            seasonId: this.selectedSeason.id,
            forecastType: "COMBINED",
          },
        });
        this.containerGraphKey++;
        this.palletGraphKey++;
        this.unitGraphKey++;
        this.loadingForecast = false;
        // let uniqueCountries = [
        //   ...new Set(
        //     this.forecastItems
        //       .filter((x) => x.destinationCountryId)
        //       .map((x) => x.destinationCountryId)
        //   ),
        // ];
        // let countries = [];
        // let totalUnits = this.forecastItems.filter(x=>x.unitQuantity).reduce(
        //   (a, b) => a + b.unitQuantity,
        //   0
        // );
        // for (let i = 0; i < uniqueCountries.length; i++) {
        //   let country = uniqueCountries[i];
        //   let countryData = this.forecastItems.filter(
        //     (x) => x.destinationCountryId == country
        //   );
        //   let countryName = countryData[0].destinationCountry.name;
        //   let containers = countryData.reduce(
        //     (a, b) => a + b.containerQuantity,
        //     0
        //   );
        //   let pallets = countryData.reduce((a, b) => a + b.palletQuantity, 0);
        //   let units = countryData.reduce((a, b) => a + b.unitQuantity, 0);
        //   countries.push({
        //     iso2: countryData[0].destinationCountry.iso2,
        //     name: countryName,
        //     containers: containers,
        //     pallets: pallets,
        //     units: units,
        //     percentage: Math.round((units / totalUnits) * 100),
        //   });
        // }
        // this.topCountries = countries
        //   .sort((a, b) => (a.units > b.units ? -1 : b.units > a.units ? 1 : 0))
        //   .slice(0, 5);

        //   let allVariants = [].concat.apply([], this.forecastItems.filter(x => x.orderCargoItems).map(x=> x.orderCargoItems))
        //   let uniqueVariants = [...new Set(allVariants.map(x => x.varietyProductId))].filter(Boolean)
        //   let varieties = []
        //   for(let i = 0; i < uniqueVariants.length; i++){
        //     let variant = uniqueVariants[i]
        //     let variantData = allVariants.filter(x => x.varietyProductId == variant)
        //     let variantName = variantData[0].varietyProduct.name
        //     let product = this.forecastItems.find(x=>x.id == variantData[0].forecastId)
        //     let units = variantData.reduce((a,b) => a + b.quantity, 0)
        //     varieties.push({
        //       product: product.product.name,
        //       name: variantName,
        //       units: units,
        //       percentage: Math.round((units / totalUnits) * 100),
        //     })
        //   }
        //   this.topVarieties = varieties.sort((a,b) => (a.units > b.units) ? -1 : (b.units > a.units) ? 1 : 0).slice(0,5)
        // this.calculateFilteredResults();
      }
    },
    async saveSeason() {
      this.savingSeason = true;
      this.season.startWeek = moment(this.season.start).week();
      this.season.endWeek = moment(this.season.end).week();
      this.season.startYear = moment(this.season.start).year();
      this.season.endYear = moment(this.season.end).year();
      let result = {};
      if (!this.season.id) {
        result = await this.$API.createSeason(this.season);
      } else {
        result = await this.$API.updateSeason(this.season);
      }
      if (this.season.productId) {
        let findProduct = this.products.data.find(
          (x) => x.id == this.season.productId
        );
        if (findProduct) {
          result.product = findProduct;
        }
      }
      let index = this.availableSeasons.findIndex((x) => x.id == result.id);
      if (index > -1) {
        this.availableSeasons.splice(index, 1, result);
      } else {
        this.availableSeasons.push(result);
      }
      this.savingSeason = false;
      this.seasonModal = false;
      this.season = {};
      this.selectedSeason = result;
    },
    async shiftSeason() {
      this.shiftingSeason = true;
      let result = await this.$API.shiftSeason({
        seasonId: this.season.id,
        weekAdjustment: this.weekAdjustment,
      });
      this.shiftingSeason = false;
      this.shiftSeasonModal = false;
      this.getSeasons();
      this.getForecast();
    },
    weeks(returnObject = false) {
      let result = [];
      if (this.selectedSeason) {
        let start = this.selectedSeason.start
          ? new Date(this.selectedSeason.start)
          : new Date();
        let end = this.selectedSeason.end
          ? new Date(this.selectedSeason.end)
          : new Date();
        let diff = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
        let weeks = Math.ceil(diff / 7);
        let currentWeek = moment(start).week();
        let currentYear = moment(start).year();

        for (let i = 0; i < weeks; i++) {
          result.push({
            name: `${currentYear} - W${currentWeek}`,
            plannedWeek: currentWeek,
            plannedYear: currentYear,
            shortYear: currentYear.toString().substr(-2),
            friendlyName: `${currentYear
              .toString()
              .substr(-2)} - W${currentWeek}`,
          });
          currentWeek++;
          if (currentWeek > 52) {
            currentWeek = 1;
            currentYear++;
          }
        }
      }
      return returnObject ? result : result.map((x) => x.friendlyName);
    },
  },
};
</script>